import axios from "axios";
import axiosInstance from "../../_utils/axiosConfig";
export const profil = async(informatons)=>{
    try{
        const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/profil/${informatons}`);
        return response.data;
    }catch(error){
         console.error('Erreur lors de la recuperation '+error)
         throw error;
    }
}