import axios from "axios";
import axiosInstance from "../../_utils/axiosConfig";
export const logo = async()=>{
    try{
        const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/logo`);
        return response.data;
    }catch(error){
        console.log("Erreur lors de la recuperation du logo ",error)
    }
        
}