import axios from "axios"
import { emitCustomEvent } from 'react-custom-events'
import axiosInstance from "../../_utils/axiosConfig";
export const add = async (informations)=>{
    try{
      const response= await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/stores`,informations);
      emitCustomEvent('customerAdded',response.data.data)
      return response.data;
    }catch(error){
        console.log("Erreur lors de l'appel a l'API ",error)
        throw error;
    }
}