import axios from "axios";
import axiosInstance from "../../_utils/axiosConfig";

export const disponibles = async () => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getTableDisponible`);
        return response.data;
    } catch (error) {
        console.error("Erreur lors de l'appel au serveur :", error);
        throw error; // Relancer l'erreur ou retourner une valeur par défaut
    }
};
