import { BsCalendarDate } from "react-icons/bs";
import { FaFilePdf, FaPowerOff, FaUser } from "react-icons/fa6";
import { IoFastFoodSharp } from "react-icons/io5";
import { MdEventBusy, MdOutlineAccessTime } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { accountService } from "../services/Account.service";

export default function Header()
{
    const location = useLocation();
    const isCommande = location.pathname==="/"? " text-white " :'';
    const isProfil = location.pathname==="/profil"? " text-white " :'';
    const isReservation = location.pathname==="/reservation"? " text-white " :'';
    const isdocument = location.pathname==="/document"? " text-white " :'';
  let navigate = useNavigate();
   const logout = ()=>{
         accountService.logout();
         navigate('/login')
   }  
   return  (<div className='md:w-3/3 bg-white barre_scroll mb-4'>
  <div className='bg-black flex xs:flex-col md:flex-row md:h-20 xs:h-96 md:space-x-4 xs:space-y-4 md:space-y-0 md:items-center justify-center text-[#D19E06] z-10 fixed top-0  w-1/3'>
    {/* Mon compte */}
    <Link to='/profil' className={'md:ml-4 flex flex-col items-center hover:text-white cursor-pointer'+ isProfil}>
        <FaUser className='text-2xl' />
        <div>Mon compte</div>
    </Link>
    {/* Commande */}
    <Link to='/' className={'flex flex-col items-center hover:text-white cursor-pointer'+isCommande}>
        <IoFastFoodSharp className='text-2xl' />
        <div>Commande</div>
    </Link>
    {/* Reservation */}
    <Link to='/reservation' className={'flex flex-col items-center  cursor-pointer'+isReservation}>
       <MdEventBusy  className='text-2xl' />
        <div>Reservation</div>
    </Link>
     {/* Document */}
     <Link to='/document' className={'flex flex-col items-center  cursor-pointer'+isdocument}>
        <FaFilePdf size={30}  />
        <div>Documents</div>
    </Link>
    {/* Deconnexion */}
    <div onClick={logout} className='flex flex-col items-center hover:text-white cursor-pointer'>
        <FaPowerOff className='text-2xl' />
        <div>Deconnexion</div>
    </div>
  </div>
 </div>)
}


 