import restoImage from "../../ASSETS/Image/top-view-cooked-chicken-spiced-up-with-potatoes-dark-surface.jpg";
import { FaUserLarge } from "react-icons/fa6";
import { IoIosLock } from "react-icons/io";
import {Link,useNavigate} from 'react-router-dom'
import { IoReturnUpBack } from "react-icons/io5";
import Loading from '../../COMPONENTS/Loading';
import { useState, useEffect } from 'react';
import {login } from '../../API/login/login'
import { accountService } from "../../services/Account.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logo } from "../../API/Logo/logo";

function Login() {

  const [loading, setLoading] = useState(true);
      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);
      const navigate = useNavigate();
      useEffect(() => {
        const token = accountService.getToken();
        if (token) {
          navigate('/');
        }
      }, [navigate]);
      const [credentials,setCredentials] = useState({
        login:'',
        password:''
      })
    let mdp = localStorage.getItem('mdp')
      if(mdp)
      {
        toast.success("Mot de passe reinitialiser avec succès");
        localStorage.removeItem('mdp')
      }
      
      const onChange = (e: any) => {
        const { name, value } = e.target;

        // Si le champ est "password", on met à jour directement
        if (name === "password") {
          setCredentials({ ...credentials, [name]: value });
          return;
        }
      
        // Vérifier si la saisie est un email ou contient des lettres
        const isEmailOrText = /[a-zA-Z@]/.test(value);
      
        if (isEmailOrText) {
          // Si c'est un email ou texte, mettre à jour normalement
          setCredentials({ ...credentials, [name]: value });
          return;
        }
      
        // Supprimer les tirets avant traitement
        let cleanedValue = value.replace(/[^0-9]/g, '');
      
        // Ajouter un tiret automatiquement après chaque 2 chiffres
        if (cleanedValue.length > 2) {
          cleanedValue = cleanedValue.match(/.{1,2}/g).join('-');
        }
      
        // Limiter à 8 chiffres (sans compter les tirets)
        if (cleanedValue.replace(/-/g, '').length > 8) {
          return; // Bloquer la saisie après 8 chiffres
        }
      
        setCredentials({ ...credentials, [name]: cleanedValue });
      };
      
      

      const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])
     
      const onSubmit = async (e: any) => {
        e.preventDefault();
        if (credentials.login === '') {
          toast.error("Veuillez remplir les champs");
        } else if (credentials.password === '') {
          toast.error("Veuillez remplir les champs");
        } else {
          console.log(credentials)
          try {

            const response = await login(credentials);
            
            // Vérifiez si la réponse contient le token et le customer avant d'accéder à ces valeurs
            if (response.token && response.customer) {
              localStorage.setItem('database',response.database);
              let country = JSON.stringify(response.customer.Country)
              localStorage.setItem('country',country)
              accountService.SaveToken(response.token);
              accountService.SaveId(response.customer.Customers_Numbers);
              navigate('/');
              toast.success(response.message);
            } else {
              toast.error(response.message);
            }
          } catch (error) {
            console.error('Erreur lors de l\'envoi du formulaire :', error);
            
            const err = error as any; // Caster l'erreur en `any`
            
            if (err.response) {
              const errorMessage = err.response.data.message || 'Une erreur est survenue';
              toast.error(errorMessage);
            } else {
              toast.error(err.message || 'Une erreur est survenue');
            }
          }
        }
      };
     
      
  return (
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
        <div
            className="bg-center bg-cover bg-no-repeat h-screen flex justify-end"
            style={{ backgroundImage: `url(${restoImage})` }}> 
            <form onSubmit={onSubmit} className="bg-white w-[600px] flex flex-col space-y-7 text-lg items-center justify-center ">
              <div className="text-4xl font-bold"><img className="w-40 h-40 rounded-full object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/></div>
              <div className="text-2xl font-bold">ON-SITE</div>
              <div className="font-semibold">Connectez-vous a votre compte</div>
              <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px]  flex flex-row items-center px-3">
                  <FaUserLarge className="text-[#a5a5a5]" />
                  <input
                      type="text"
                      value={credentials.login}
                      name="login"
                      onChange={onChange}
                      className=" h-12 px-3 w-[370px] bg-transparent outline-none text-base"
                      placeholder="Nom d'utilisateur"
                  />
              </div>
              <ToastContainer />
              <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px] flex flex-row items-center px-3">
                  <IoIosLock className="text-[#a5a5a5] text-xl" />
                  <input
                      type="password"
                      value={credentials.password}
                      name="password"
                      onChange={onChange}
                      className=" h-12 px-3 w-[370px] bg-transparent outline-none text-base"
                      placeholder="Mot de passe"
                  />
              </div>
              <Link to="/forgotPassword">Mot de passe oublié ?</Link>
              <button className="bg-[#D19E06] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white hover:text-[#D19E06] s:w-[400px] xs:w-[240px] sm:w-[300px] text-white h-12">Connexion</button>
              {/* <Link to='/profil'>
                  <button className="bg-[#D19E06] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white hover:text-[#D19E06] s:w-[400px] xs:w-[240px] sm:w-[300px] text-white h-12">Connexion</button>
              </Link> */}

              <Link to='/' className="flex hover:text-[#D19E06] items-center space-x-2 text-sm">
                <IoReturnUpBack className="text-xl" />
                <div>Retour a la page d'accueil</div>
              </Link>
            </form>
        </div>
      </>
      )}
    </div>
   
  );
}

export default Login;
