import { useEffect, useState } from "react";
import { profil } from "../API/profil/profil";
import { update } from "../API/Update/update";
import { toast } from "react-toastify";
import { accountService } from "../services/Account.service";
import { FaPhone, FaUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoIosLock, IoMdPin } from "react-icons/io";

export default function FormProfil()
{
    const [profils, setProfils] = useState([]);
    const [regle,setRegle]= useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting,setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        lastname: "",
        firstname: "",
        username: "",
        mail: "",
        phone: "",
        adresse: "",
        password: ""
      });

      const id = accountService.getId();
      useEffect(() => {
        profil(id).then((response) => {
          setProfils(response.profils);
          setRegle(response.reglement)
          setFormData({
            lastname: response.profils?.LastName || "",
            firstname: response.profils?.FirstName || "",
            username: response.profils?.username || "",
            mail: response.profils?.Email || "",
            phone: response.profils?.Phones || "",
            adresse: response.profils?.Adress || "",
            password: ""
          });
        }).catch(error => {
          console.log("Erreur lors de la récupération " + error);
        });
      }, [id]);

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await update(id, formData);
          if(response.status){
            setProfils(response.newProfils)
            setMessage(response.message || 'Utilisateur mis à jour avec succès');
            toast.success(response.message || 'Utilisateur mis à jour avec succès');
            setIsSubmitting(true)
          }
         
        } catch (error) {
          setMessage('Erreur lors de la mise à jour de l\'utilisateur');
          console.error(error); // Affichez l'erreur pour le débogage
          
          // Si l'erreur a une réponse et contient des messages d'erreur spécifiques, les afficher
          if (error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            for (const key in errors) {
              if (errors.hasOwnProperty(key)) {
                if (Array.isArray(errors[key])) {
                  errors[key].forEach(errMsg => {
                    toast.error(errMsg);
                  });
                } else {
                  toast.error(errors[key]);
                }
              }
            }
          } else {
            toast.error('Erreur lors de la mise à jour de l\'utilisateur');
          }
        }
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };
    

    return (
        <div>
                <div className="md:mt-32 xs:mt-80 pb-2 pl-6 font-medium xs:text-base md:text-xl">
                Modifier mon compte
                </div>
                <div className="w-[340px] pl-6">
                Veuillez remplir le formulaire ou le champ concerné pour modifier vos informations
                </div>
            <form onSubmit={handleSubmit}>
                <div className="p-6 flex flex-col space-y-5">
                    {/* Nom */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px]  md:w-[340px] rounded-md flex items-center px-3">
                    <FaUser className="text-[#C2C2C2]" />
                    <input
                        type="text"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        name="lastname"
                        placeholder="Nom"
                        value={formData.lastname}
                        onChange={handleChange}
                    />
                    </div>
                    {/* Prénom */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px] md:w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <FaUser className="text-[#C2C2C2]" />
                    <input
                        type="text"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        placeholder="Prénom"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                    />
                    </div>
                    {/* Nom d'utilisateur */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px] md:w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <FaUser className="text-[#C2C2C2]" />
                    <input
                        type="text"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        placeholder="Nom d'utilisateur"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    </div>
                    {/* Email */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px] md:w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <MdEmail className="text-[#C2C2C2]" />
                    <input
                        type="email"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        placeholder="Adresse mail"
                        name="mail"
                        value={formData.mail}
                        onChange={handleChange}
                    />
                    </div>
                    {/* Numéro */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px] md:w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <FaPhone className="text-[#C2C2C2]" />
                    <input
                        type="tel"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        placeholder="Numéro d'urgence"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    </div>
                    {/* Adresse */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px] md:w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <IoMdPin className="text-[#C2C2C2]" />
                    <input
                        type="text"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        placeholder="Adresse"
                        name="adresse"
                        value={formData.adresse}
                        onChange={handleChange}
                    />
                    </div>
                    {/* Mot de passe */}
                    <div className="border-2 border-[#C2C2C2] sm:w-[300px] md:w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <IoIosLock className="text-[#C2C2C2]" />
                    <input
                        type="password"
                        className="sm:w-[190px] md:w-[300px] px-2 h-10 outline-none"
                        name="password"
                        placeholder="Nouveau mot de passe"
                        onChange={handleChange}
                    />
                    </div>
                    <button
                    disabled={isSubmitting}
                    className={`btn ${isSubmitting ? 'bg-gray-500' : 'bg-black'}  text-white sm:w-[300px] md:w-[340px] h-10 rounded-md`}
                    >
                    Modifier
                    </button>

                </div>
            </form>
        </div>
    )
}