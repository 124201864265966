import axios from 'axios';


// Créez une instance axios
const axiosInstance = axios.create({
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false, // Tester sans inclure les cookies
});

// Ajoutez un intercepteur pour vérifier les en-têtes de chaque requête
axiosInstance.interceptors.request.use(request => {
  const db = localStorage.getItem('database');
  //console.log(db)
  
  // Ajoutez les en-têtes si les valeurs existent
  if (db) {
    request.headers['X-Database'] = db;
  }
//   if (token) {
//     request.headers['X-Unique-ID'] = token;
//   }

 // console.log('Starting Request', request);
  return request;
}, error => {
  // Gérez les erreurs de requête
  return Promise.reject(error);
});

export default axiosInstance;