import React, { createContext, useEffect, useState} from 'react';

// Créer le contexte
export const CartContext = createContext();

// Provider pour le contexte
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const searchParams = new URLSearchParams(window.location.search);
  const idItem = searchParams.get('id');
  //console.log(paniers)
  //useEffect(() => {
    //  const paniers = JSON.parse(localStorage.getItem('panier'));
      //if (paniers && paniers.length > 0) {
        //paniers.forEach((item) => {
          //setCart((prevItem) => [...prevItem, item]);  // Ajoute chaque élément individuellement
        //});
      //}
  //}, []);
  
 // const addToCart = (product) => {
   // setCart((prevCart) => {
     // const existingProductIndex = prevCart.findIndex((item) => item.id === product.id);
      //if (existingProductIndex >= 0) {
        //const updatedCart = [...prevCart];
        //updatedCart[existingProductIndex].quantity += 1;
        //return updatedCart;
      //} else {
        //return [...prevCart, { ...product, quantity: 1 }];
      //}
    //});
  //};

  const addToCart = (product) => {
    let panier = JSON.parse(localStorage.getItem('panier')) || []; 
    const prod = panier.find((pan) => pan.id === product.id);  
    if (prod) {
      prod.quantity += 1;
    } else {
      product.quantity = 1;
      panier.push(product);
    }
    localStorage.setItem('panier', JSON.stringify(panier));
    setCart(panier);
  };
  

  const updateQuantity = (productId, quantity) => {
    setCart((prevCart) => {
      return prevCart.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      );
    });
  };

  //const removeProduct = (productId) => {
    //setCart((prevCart) => {
      //  return prevCart.filter((item) => item.id !== productId);
    //});
//};

const removeProduct = (productId) => {
   let panier = JSON.parse(localStorage.getItem('panier')) || [];
   const productIndex = panier.findIndex((pan) => pan.id === productId);
 
   if (productIndex !== -1) {
     panier.splice(productIndex, 1); 
     localStorage.setItem('panier', JSON.stringify(panier));
     setCart(panier);
   } else {
     console.log("Produit non trouvé dans le panier.");
   }
};

const calculateTotal = () => {
    return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
};

const clearCart = ()=>{
  return setCart([]);
}

//const decreaseQuantity = (productId) => {
    //setCart((prevCart) => {
      //return prevCart.map((item) =>
        //item.id === productId && item.quantity > 1
          //? { ...item, quantity: item.quantity - 1 }
          //: item
      //);
    //});
  //};

  const decreaseQuantity = (productId) => {
    let panier = JSON.parse(localStorage.getItem('panier')) || [];
    const productIndex = panier.findIndex((pan) => pan.id === productId);
    if (productIndex !== -1) {
      if (panier[productIndex].quantity > 1) {
        panier[productIndex].quantity -= 1;
      }
      localStorage.setItem('panier', JSON.stringify(panier));
    }
    setCart(panier);
  };
  return (
    <CartContext.Provider value={{ cart,setCart,addToCart, updateQuantity, decreaseQuantity,removeProduct,calculateTotal,clearCart}}>
      {children}
    </CartContext.Provider>
  );
};
